import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from '@reach/router';

class NotFound extends React.Component {

    render() {
        return (
            <Row id="notFound">
                <Col>
                    <h2>Ungültige Adresse</h2>
                    <Link to="/">Zur Startseite</Link>
                </Col>
            </Row>
        )
    }

}

export default NotFound;