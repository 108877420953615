import React from 'react';
import FormHelpers from './formHelpers';
import { Row, Col, Form, Input, FormGroup, Label, Button } from 'reactstrap';

class MessageForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            /* begin vars for formHelpers */
            submitInProgress: false,
            formContent: {
                subject: '',
                content: ''
            },
            formErrors: {},
            showSuccess: false,
            /* end vars for formHelpers */
        }
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h2>Neue Nachricht</h2>
                        <FormHelpers.GlobalErrors formErrors={this.state.formErrors} />
                        <Form onSubmit={(e) => { FormHelpers.submitHandler.bind(this, e, '/message', "POST", null).call(); }}>
                            <Row form>
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label for="subject">Betreff</Label>
                                        <Input className={FormHelpers.hasFieldErrors('subject', this.state.formErrors) ? "is-invalid" : ""} type='text' name='subject' value={this.state.formContent.subject} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="subject" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="content">Nachricht</Label>
                                        <Input className={FormHelpers.hasFieldErrors('content', this.state.formErrors) ? "is-invalid" : ""} type='textarea' rows="12" name='content' value={this.state.formContent.content} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="content" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup style={{ display: this.state.submitInProgress ? 'none' : ''}}>
                                <Button type="submit">Absenden</Button>
                            </FormGroup>
                            <div className="loader" style={{ display: this.state.submitInProgress ? '' : 'none'}} />
                        </Form>
                    </Col>
                </Row>
                <FormHelpers.SuccessModal isOpen={this.state.showSuccess} successMessage="Die Nachricht wurde abgeschickt." successPath="/messages" />
            </>
        )
    }
}

export default MessageForm;