import React from 'react';
import { Container } from 'reactstrap';
import { Router, navigate } from '@reach/router';
import Auth from './security/auth';
import Login from './Login';
import ChangePassword from './ChangePassword';
import Shares from './Shares';
import Messages from './Messages';
import AdminUsers from './AdminUsers';
import AdminShares from './AdminShares';
import UserForm from './UserForm';
import ShareForm from './ShareForm';
import MessageForm from './MessageForm';
import MyNavbar from './MyNavbar';
import Footer from './Footer';
import NotFound from './NotFound';
import {SERVER_URL} from './config';
import { defaultErrorHandler } from './handlers/errorHandlers';

class App extends React.Component {

	state = {
      serverInfo: {},
      loginData: {}
	}

    reset = () => {
        this.setState({
          serverInfo: {},
          loginData: {}
        });
        navigate('/login');
        this.reloadServerInfo();
    }

    updateLoginData(data) {
        this.setState({ loginData: data });
        this.reloadServerInfo();
    }

    loginHandlers = {
        logIn: (credentials, errorHandler) => {
            Auth.logIn(credentials, (loginData) => {
                if (loginData !== false) {
                    navigate('/');
                    this.updateLoginData(loginData);
                } else {
                    errorHandler();
                }
            });
        },
        logOut: (e) => {
            e.preventDefault();
            Auth.logOut();
            this.reset();
        }
    }

    componentDidMount() {
        (async () => {
            if (await Auth.loggedIn((ld) => this.updateLoginData(ld))) {
                if (window.location.pathname === '/login') {
                    navigate('/');
                }
            } else if (window.location.pathname !== '/login') {
                navigate('/login');
            }
        })();
    }

    reloadServerInfo() {
        fetch(SERVER_URL + '/application', { headers: Auth.headers() })
            .then(r => r.json())
            .then(json => {
                this.setState({serverInfo: json});
            })
            .catch(error => defaultErrorHandler(error));
    }

	render() {
		return (
            <Container>
                <MyNavbar loginData={this.state.loginData} loginHandlers={this.loginHandlers} />
                    <Router>
                        <Login path="/login" loginHandlers={this.loginHandlers} />
                        {this.state.loginData.id !== undefined &&
                            <>
                                <Shares path="/" />
                                <ChangePassword path="/changePassword" loginData={this.state.loginData} />
                                <AdminUsers path="/admin/users" />
                                <AdminShares path="/admin/shares" />
                                <ShareForm path="/admin/createShare" />
                                <ShareForm path="/admin/editShare/:shareId" />
                                <UserForm path="/admin/createUser" />
                                <UserForm path="/admin/editUser/:userId" />
                                <Messages path="/messages" />
                                <MessageForm path="/messages/create" />
                                <NotFound default />
                            </>
                        }
                    </Router>
                <Footer serverInfo={this.state.serverInfo} />
            </Container>
		)
	}
}

export default App;
