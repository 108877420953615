import {SERVER_URL, OAUTH_SERVER_URL} from './../config';
import {checkResponseStatus} from './../handlers/responseHandlers';
import 'whatwg-fetch';
import qs from 'qs';
import { navigate } from '@reach/router';

export default {

    logIn(credentials, resultHandler) {
        fetch(`${SERVER_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        }).then(checkResponseStatus)
        .then(response => {
            localStorage.auth = JSON.stringify(response);
            resultHandler({
                id: response.id,
                fullName: response.fullName,
                isAdmin:  response.isAdmin
            });
        })
        .catch(error => resultHandler(false));
    },

    logOut() {
        delete localStorage.auth;
    },

    async refreshToken(loginDataHandler) {
        try {
            var result = await fetch(`${OAUTH_SERVER_URL}/access_token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: qs.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: JSON.parse(localStorage.auth).refresh_token
                })
            });
            var json = await checkResponseStatus(result);
            localStorage.auth = JSON.stringify(json);
            loginDataHandler({
                id: json.id,
                fullName: json.fullName,
                isAdmin: json.isAdmin
            });
            return true;
        } catch (ex) {
            this.logOut();
            loginDataHandler({});
            alert("Sie waren zu lange inaktiv und wurden abgemeldet. Bitte melden Sie sich erneut an.");
            return false;
        }
    },

    async loggedIn(loginDataHandler) {
        if (!localStorage.auth) {
            loginDataHandler({});
            return false;
        }
        try {
            var result = await fetch(`${SERVER_URL}/loggedInPing`, { headers: this.headers() });
            var json = await checkResponseStatus(result);
            loginDataHandler({
                id: json.id,
                fullName: json.fullName,
                isAdmin: json.isAdmin
            });
            return true;
        } catch (ex) {
            return await this.refreshToken(loginDataHandler);
        }
    },

    headers() {
        if (localStorage.auth) {
            return {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.auth).access_token
            }
        } else {
            return {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

};