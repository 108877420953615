import React from 'react';
import {Table, Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { SERVER_URL } from './config';
import Auth from './security/auth';
import 'whatwg-fetch';
import { defaultErrorHandler } from './handlers/errorHandlers';
import DateHelpers from './dateHelpers';
import { navigate, Link } from '@reach/router';

class AdminShares extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmToDelete: null // holds the id of the share about to be deleted
        };
    }

    reloadShares(clearList) {
        if (clearList) {
            this.setState({queryResult: null});
        }
        fetch(`${SERVER_URL}/share`, {
            method: 'GET',
            headers: Auth.headers()
        })
        .then(r => r.json())
        .then(json => this.setState({queryResult:json}))
        .catch(error => defaultErrorHandler(error));
    }

    deleteShare() {
        let toDelete = this.state.confirmToDelete;
        this.setState({ confirmToDelete:null });

        fetch(`${SERVER_URL}/share/${toDelete}`, {
             method: 'DELETE',
             headers: Auth.headers()
        })
        .then(r => {
            if (r.status === 204) {
                this.reloadShares(true);
            } else {
                throw new Error("Ungültige Antwort des Servers beim Löschen.");
            }
        })
        .catch(error => defaultErrorHandler(error));
    }

    componentDidMount() {
        this.reloadShares(false);
    }



    render() {
        return (
            <Row>
                <Col>
                <h2>Anteilsverwaltung</h2>
                <p>
                    <Button onClick={() => {navigate('/admin/createShare')}}>Neuer Anteil</Button>
                </p>
                <Table id="shares">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Größe</th>
                            <th>Eigentümer</th>
                            <th>Erstellt</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.queryResult && this.state.queryResult.shares.map((share, idx) => (
                            <tr key={share.id}>
                                <td><Link to={"/admin/editShare/"+share.id}>{share.description}</Link></td>
                                <td className="number">{share.value.toFixed(1)}</td>
                                <td>{share.owner ? share.owner.fullName : ''}</td>
                                <td className="date">{DateHelpers.formatDateTime(share.dateCreated)}</td>
                                <td className="actions"><Button onClick={() => {this.setState({confirmToDelete:share.id})}}><i className="fa fa-trash" aria-hidden="true"></i></Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                </Col>
                <Modal isOpen={this.state.confirmToDelete != null}>
                    <ModalBody>
                        Soll der Anteil wirklich gelöscht werden?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.deleteShare()}>Bestätigen</Button>
                        <Button color="secondary" onClick={() => this.setState({confirmToDelete:null})}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>
            </Row>
        );
    }

}

export default AdminShares;