import React from 'react';
import {Row, Col, Form, Input, FormGroup, Label, Button} from 'reactstrap';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formContent: {
                username: '',
                password: ''
            },
            loginError: false
        }
    }

    inputChangeHandler = (event) => {
        let {formContent} = this.state;
        const target = event.target;
        formContent[target.name] = target.value;
        this.setState({formContent});
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.props.loginHandlers.logIn(this.state.formContent, () => {
            this.setState({ loginError: true });
        });
    }

    render() {
        return (
            <Row id="loginForm">
                <Col sm={{ size: 3 }} md={{ size: 4, offset: 2 }} className="d-none d-sm-block">
                    <img src="/images/login.jpg" alt="Hausfassade der Rainergasse 31" />
                </Col>
                <Col xs="12" sm ="9" md="4">
                    {this.state.loginError ? <p className="alert alert-danger">Anmeldung fehlgeschlagen</p> : null}
                    <Form onSubmit={this.onSubmit}>
                        <FormGroup>
                            <Label for="username">Benutzername</Label>
                            <Input type="text" name='username' value={this.state.formContent.username} onChange={this.inputChangeHandler} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Passwort</Label>
                            <Input type='password' name='password' value={this.state.formContent.password} onChange={this.inputChangeHandler} />
                        </FormGroup>
                        <FormGroup>
                            <Button type="submit">Anmelden</Button>
                        </FormGroup>
                    </Form>
                    <p className="forgotPasswordHint">
                        Passwort vergessen?<br />Bitte rufen Sie +43 699 10886656 an.
                    </p>
                </Col>
            </Row>
        )
    }

};

export default Login;