import React from 'react';
import FormHelpers from './formHelpers';
import { Row, Col, Form, Input, FormGroup, Label, Button } from 'reactstrap';

class UserForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            /* begin vars for formHelpers */
            submitInProgress: false,
            formContent: {
                username: '',
                password: '',
                fullName: '',
                email: '',
                phone: '',
                enabled: false,
                isAdmin: false
            },
            formErrors: {},
            showSuccess: false,
            /* end vars for formHelpers */
        }
    }

    componentDidMount() {
        if (this.props.userId != null) {
            FormHelpers.populate.bind(this, '/user/' + this.props.userId).call();
        }
    }

    preSubmitContentHandler() {
        const result = {};
        Object.assign(result, this.state.formContent);
        if (this.props.userId != null && result.password === "") {
            delete result.password;
        }
        return result;
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h2>Benutzer bearbeiten</h2>
                        <FormHelpers.GlobalErrors formErrors={this.state.formErrors} />
                        <Form onSubmit={(e) => { FormHelpers.submitHandler.bind(this, e, (this.props.userId != null) ? `/user/${this.props.userId}` : `/user`, (this.props.userId != null) ? "PUT" : "POST", this.preSubmitContentHandler()).call(); }}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="username">Login</Label>
                                        <Input className={FormHelpers.hasFieldErrors('username', this.state.formErrors) ? "is-invalid" : ""} type='text' name='username' value={this.state.formContent.username} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="username" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="password">(Neues) Passwort</Label>
                                        <Input className={FormHelpers.hasFieldErrors('password', this.state.formErrors) ? "is-invalid" : ""} type='password' name='password' value={this.state.formContent.password ? this.state.formContent.password : ''} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="password" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="fullName">Name</Label>
                                        <Input className={FormHelpers.hasFieldErrors('fullName', this.state.formErrors) ? "is-invalid" : ""} type='text' name='fullName' value={this.state.formContent.fullName} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="fullName" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input className={FormHelpers.hasFieldErrors('email', this.state.formErrors) ? "is-invalid" : ""} type='email' name='email' value={this.state.formContent.email} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="email" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="phone">Telefon</Label>
                                        <Input className={FormHelpers.hasFieldErrors('phone', this.state.formErrors) ? "is-invalid" : ""} type='text' name='phone' value={this.state.formContent.phone} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="phone" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input className={FormHelpers.hasFieldErrors('isAdmin', this.state.formErrors) ? "is-invalid" : ""} name="isAdmin" type="checkbox" checked={this.state.formContent.isAdmin} onChange={FormHelpers.inputChangeHandler.bind(this)} />{' '}Administrator
                                        </Label>
                                        <FormHelpers.FieldErrors field="isAdmin" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input className={FormHelpers.hasFieldErrors('enabled', this.state.formErrors) ? "is-invalid" : ""} name="enabled" type="checkbox" checked={this.state.formContent.enabled} onChange={FormHelpers.inputChangeHandler.bind(this)} />{' '}Aktiv
                                        </Label>
                                        <FormHelpers.FieldErrors field="enabled" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Button type="submit">Speichern</Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <FormHelpers.SuccessModal isOpen={this.state.showSuccess} successMessage="Der Benutzer wurde gespeichert." successPath="/admin/users" />
            </>
        )
    }
}

export default UserForm;