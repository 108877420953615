import React from 'react';
import { Link } from '@reach/router';
import {Navbar, NavbarBrand, Nav, NavLink, NavItem, NavbarToggler, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { SERVER_URL } from './config';
import Auth from './security/auth';

class MyNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toggle() {
        this.setState((oldState, props) => {
            return {
                isOpen: !oldState.isOpen
            }
        });
    }

    renderLoggedOut() {
        return (
            <Navbar dark color="dark" id="navbar" expand="md">
                <NavbarBrand>Eigentümerportal Rainergasse 31</NavbarBrand>
            </Navbar>
        )
    }

    sendTestEmail(e) {
        e.preventDefault();
        fetch(`${SERVER_URL}/sendTestEmail`, {
            headers: Auth.headers(),
            method: 'GET',
            cache: 'no-cache'
        });
    }

    adminItems(loginData) {
        if (!loginData.isAdmin) return;
        return (
            <>
                <DropdownItem>
                   <Link className="nav-link" to="/admin/shares">Anteilsverwaltung</Link>
                </DropdownItem>
                <DropdownItem>
                   <Link className="nav-link" to="/admin/users">Benutzerverwaltung</Link>
                </DropdownItem>
                <DropdownItem>
                    <a className="nav-link" href="/" onClick={(e) => this.sendTestEmail(e)}>Test Mail &amp; Log</a>
                </DropdownItem>
            </>
        );
    }

    render() {
        if (this.props.loginData === undefined || this.props.loginData.id === undefined) return this.renderLoggedOut();
        return (
           	<Navbar dark color="dark" id="navbar" expand="md">
           	    <Link className="navbar-brand" to="/">Eigentümerportal Rainergasse 31</Link>
           	    <NavbarToggler onClick={() => this.toggle()} />
           	    <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto">
                        <NavItem>
                          <Link className="nav-link" to="/">Kontaktliste</Link>
                        </NavItem>
                        <NavItem>
                          <Link className="nav-link" to="/messages">Nachrichten</Link>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                {this.props.loginData.fullName}
                            </DropdownToggle>
                            <DropdownMenu right id="loginMenu">
                                {this.adminItems(this.props.loginData)}
                                <DropdownItem>
                                   <Link className="nav-link" to="/changePassword">Passwort ändern</Link>
                                </DropdownItem>
                                <DropdownItem>
                                <NavLink onClick={this.props.loginHandlers.logOut} href="#">Abmelden</NavLink>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                       </Nav>
                </Collapse>
           	</Navbar>
       )
    }
}

export default MyNavbar;