import React from 'react';
import {Table, Row, Col } from 'reactstrap';
import { SERVER_URL } from './config';
import Auth from './security/auth';
import 'whatwg-fetch';
import { defaultErrorHandler } from './handlers/errorHandlers';

class Shares extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        fetch(`${SERVER_URL}/share/publicIndex`, {
            method: 'GET',
            headers: Auth.headers()
        })
        .then(r => r.json())
        .then(json => this.setState({queryResult:json}))
        .catch(error => defaultErrorHandler(error));
    }

    render() {
        return (
            <Row><Col>
            <h2>Kontaktliste</h2>
            <Table id="shares">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Top</th>
                        <th>Anteile</th>
                        <th>Prozent</th>
                        <th>Telefon</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
					{this.state.queryResult && this.state.queryResult.shares.map((share, idx) => (
						<tr key={share.id}>
						    <td>{share.owner ? share.owner.fullName : ''}</td>
						    <td className="number">{share.description}</td>
						    <td className="number">{share.value.toFixed(1)}</td>
						    <td className="number">{this.state.queryResult.totalValue > 0 ? (share.value/this.state.queryResult.totalValue*100).toFixed(1): '0'}%</td>
						    <td>{share.owner ? share.owner.phone : ''}</td>
						    <td>{share.owner ? share.owner.email : ''}</td>
						</tr>
					))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td className="number">{this.state.queryResult ? this.state.queryResult.totalValue.toFixed(1) : ''}</td>
                        <td className="number">100.0%</td>
                        <td className="number">{this.state.queryResult ? (this.state.queryResult.totalValueWithPhone/this.state.queryResult.totalValue*100).toFixed(1) : ''}%</td>
                        <td className="number">{this.state.queryResult ? (this.state.queryResult.totalValueWithEmail/this.state.queryResult.totalValue*100).toFixed(1) : ''}%</td>
                    </tr>
                </tfoot>
            </Table>
            </Col></Row>
        );
    }

}

export default Shares;
