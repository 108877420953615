import React from 'react';
import { Row, Col, Button, Card, CardBody, CardHeader, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { SERVER_URL } from './config';
import Auth from './security/auth';
import 'whatwg-fetch';
import { defaultErrorHandler } from './handlers/errorHandlers';
import { navigate } from '@reach/router';
import MyPagination from './MyPagination';
import DateHelpers from './dateHelpers';

class Messages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openMessage: null,
            showRecipients: null
        };
    }

    loadMessages(offset) {
        fetch(`${SERVER_URL}/message/publicIndex?offset=${offset}`, {
            method: 'GET',
            headers: Auth.headers()
        })
        .then(r => r.json())
        .then(json => this.setState({ queryResult:json, openMessage: null, showRecipients: null }))
        .catch(error => defaultErrorHandler(error));
    }

    componentDidMount() {
        this.loadMessages(0);
    }

    openMessage(id) {
        if (this.state.openMessage === id) {
            this.setState({ openMessage: null });
        } else {
            this.setState({ openMessage: id });
        }
    }

    showRecipients(e, recipients) {
        e.stopPropagation();
        this.setState({ showRecipients: recipients });
    }

    getRecipientClass(recipient) {
        if (recipient.status === "FAILED")
            return 'recipient-failed';
        else if (recipient.status === "PENDING")
            return 'recipient-pending';
        else
            return 'recipient-sent';
    }

    render() {
        let qR = this.state.queryResult ? this.state.queryResult : {};
        return (
            <>
            <Row>
                <Col>
                    <h2>Nachrichten</h2>
                    <p>
                        <Button onClick={() => {navigate('/messages/create')}}>Neue Nachricht verfassen</Button>
                    </p>
                </Col>
            </Row>
            <div className="accordion" id="messages">
                {qR.messages && qR.messages.map((msg, idx) => (
                    <Card key={msg.id}>
                        <CardHeader className="card-header" onClick={() => this.openMessage(msg.id)}>
                            <Row>
                                <Col xs="7" md="9">
                                    {msg.authorFullName}
                                </Col>
                                <Col xs="5" md="3" className="text-right">
                                    {DateHelpers.formatDateTime(msg.dateCreated)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="7" md="9">
                                    <b>{msg.subject}</b>
                                </Col>
                                <Col xs="5" md="3" className="text-right">
                                    <i className="fa fa-users" onClick={(e) => this.showRecipients(e, msg.recipients)}></i>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Collapse isOpen={this.state.openMessage === msg.id}>
                            <CardBody>
                                <div style={{'whiteSpace':'pre-wrap'}}>{msg.content}</div>
                            </CardBody>
                        </Collapse>
                    </Card>
                ))}
            </div>
            <MyPagination max={qR.max} totalCount={qR.totalCount} offset={qR.offset} paginationHandler={(offset) => this.loadMessages(offset)} />
            <Modal isOpen={this.state.showRecipients !== null}>
                <ModalHeader>Empfänger</ModalHeader>
                <ModalBody>
                    <ul>
                        {this.state.showRecipients !== null && this.state.showRecipients.map((recipient, idx) => (
                            <li key={recipient.id} class={this.getRecipientClass(recipient)}>
                                {recipient.fullName} ({recipient.email})
                            </li>
                        ))}
                    </ul>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => this.showRecipients(e, null)}>schließen</Button>
                </ModalFooter>
            </Modal>
            </>
        );
    }

}

export default Messages;