import React from 'react';
import {Row,Col} from 'reactstrap';
import {CLIENT_VERSION} from './config';

class DebugData extends React.Component {

    render() {
        return (
            <Row id="debugInfo">
                <Col>
                    Debug Information:
                    <ul>
                        <li>Client version: {CLIENT_VERSION}</li>
                        <li>Server version: {this.props.serverInfo.appversion}</li>
                        <li>Server environment: {this.props.serverInfo.environment}</li>
                        <li>Grails version: {this.props.serverInfo.grailsversion}</li>
                        <li>Groovy version: {this.props.serverInfo.groovyversion}</li>
                        <li>JVM: {this.props.serverInfo.jvmvendor} {this.props.serverInfo.jvmversion}</li>
                        <li>Reloading agent: {this.props.serverInfo.reloadingagentenabled ? 'yes' : 'no'}</li>
                    </ul>
                </Col>
            </Row>
        );
    }
}

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clicksToServerInfo: 6
        };
    }

    onClick() {
        this.setState((state, props) => {
            if (state.clicksToServerInfo > 0) {
                return { clicksToServerInfo: (state.clicksToServerInfo - 1) }
            }
        });
    }

    render() {
        return (
            <>
                <Row id="footer" onClick={() => this.onClick()}>
                    <Col id="copyright">
                        ©{this.props.serverInfo.currentYear ? this.props.serverInfo.currentYear : '2020'} <a href="https://www.alexandrow.org" target="_blank" rel="noopener noreferrer">Paul Alexandrow</a>
                    </Col>
                </Row>
                {this.state.clicksToServerInfo === 0 ? <DebugData serverInfo={this.props.serverInfo} /> : null}
            </>
        )
    }

}

export default Footer;