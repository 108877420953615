import React from 'react';
import FormHelpers from './formHelpers';
import { Row, Col, Form, Input, FormGroup, Label, Button } from 'reactstrap';
import { SERVER_URL } from './config';
import Auth from './security/auth';
import 'whatwg-fetch';
import { defaultErrorHandler } from './handlers/errorHandlers';

class ShareForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            /* begin vars for formHelpers */
            submitInProgress: false,
            formContent: {
                value: '',
                description: '',
                'owner.id': null
            },
            formErrors: {},
            showSuccess: false,
            /* end vars for formHelpers */
            ownerOptions: []
        }
    }

    componentDidMount() {
        fetch(`${SERVER_URL}/share/ownerOptions`, {
            method: 'GET',
            headers: Auth.headers()
        })
        .then(r => r.json())
        .then(json => this.setState({ownerOptions:json}))
        .catch(error => defaultErrorHandler(error));

        if (this.props.shareId != null) {
            FormHelpers.populate.bind(this, '/share/' + this.props.shareId).call();
        }
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h2>Anteil bearbeiten</h2>
                        <FormHelpers.GlobalErrors formErrors={this.state.formErrors} />
                        <Form onSubmit={(e) => { FormHelpers.submitHandler.bind(this, e, (this.props.shareId != null) ? `/share/${this.props.shareId}` : `/share`, (this.props.shareId != null) ? "PUT" : "POST").call(); }}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="description">Top</Label>
                                        <Input className={FormHelpers.hasFieldErrors('description', this.state.formErrors) ? "is-invalid" : ""} type='text' name='description' value={this.state.formContent.description} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="description" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="value">Wert</Label>
                                        <Input className={FormHelpers.hasFieldErrors('value', this.state.formErrors) ? "is-invalid" : ""} type='text' name='value' value={this.state.formContent.value} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                        <FormHelpers.FieldErrors field="value" formErrors={this.state.formErrors} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="owner.id">Eigentümer</Label>
                                        <Input className={FormHelpers.hasFieldErrors('owner.id', this.state.formErrors) ? "is-invalid" : ""} type="select" name="owner.id" id="owner.id" onChange={FormHelpers.inputChangeHandler.bind(this)}>
                                            <option key="null" value="null"></option>
                                            {this.state.ownerOptions.map((user, idx) => (
                                                <option key={user.id} value={user.id} selected={user.id === this.state.formContent['owner.id'] ? 'selected' : null}>{user.fullName}</option>
                                            ))}
                                            <FormHelpers.FieldErrors field="owner.id" formErrors={this.state.formErrors} />
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Button type="submit">Speichern</Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <FormHelpers.SuccessModal isOpen={this.state.showSuccess} successMessage="Der Anteil wurde gespeichert." successPath="/admin/shares" />
            </>
        )
    }
}

export default ShareForm;