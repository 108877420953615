import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class MyPagination extends React.Component {

    handleClick(e, offset) {
        e.preventDefault();
        this.props.paginationHandler(offset);
    }

    render() {
        if (this.props.totalCount <= this.props.max) return null;

        let pageCount = Math.ceil(this.props.totalCount / this.props.max);
        let currentPage = Math.floor(this.props.offset / this.props.max);

        let pageLinks = [];
        for (var i=0; i<pageCount; i++) {
            pageLinks.push({
                page: i+1,
                offset: i*this.props.max,
                active: (i === currentPage)
            });
        }

        return (
            <Pagination className="myPagination d-flex justify-content-center mt-4">
                <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink href="#" onClick={(e) => this.handleClick(e, 0)}>&laquo;</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink href="#" onClick={(e) => this.handleClick(e, (currentPage-1) * this.props.max)}>&lsaquo;</PaginationLink>
                </PaginationItem>
                {pageLinks.map((item) => (
                    <PaginationItem active={item.active} key={'page' + item.page}>
                        <PaginationLink href="#" onClick={(e) => this.handleClick(e, item.offset)}>{item.page}</PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === (pageCount-1)}>
                    <PaginationLink href="#" onClick={(e) => this.handleClick(e, (currentPage+1) * this.props.max)}>&rsaquo;</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={currentPage === (pageCount-1)}>
                    <PaginationLink href="#" onClick={(e) => this.handleClick(e, (pageCount-1) * this.props.max)}>&raquo;</PaginationLink>
                </PaginationItem>
            </Pagination>
        );
    }

}

export default MyPagination;