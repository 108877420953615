import React from 'react';
import {SERVER_URL} from './config';
import { FormFeedback, Modal, ModalBody, ModalFooter, Button, Row, Col, Alert } from 'reactstrap';
import Auth from './security/auth';
import { navigate } from '@reach/router';
import { defaultErrorHandler } from './handlers/errorHandlers';

export default {

    inputChangeHandler(e) {
        let formContent = this.state.formContent;
        const target = e.target;
        if (e.target.type === 'checkbox') {
            formContent[target.name] = target.checked;
        } else {
            formContent[target.name] = target.value;
        }
        this.setState({ formContent: formContent });
    },

    submitHandler(e, url, method, customContent) {
        e.preventDefault();
        if (this.state.submitInProgress) return;
        this.setState({ submitInProgress: true });

        const responseData = {};
        fetch(`${SERVER_URL}${url}`, {
            'method': method,
            headers: Auth.headers(),
            body: JSON.stringify(customContent  != null ? customContent : this.state.formContent)
        })
        .then(r => {
            responseData.status = r.status;
            responseData.statusText = r.statusText;
            const contentType = r.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return r.json();
            } else {
                return r.text();
            }
        })
        .then(json => {
            this.setState({ submitInProgress: false });
            if(responseData.status === 200 || responseData.status === 201) {
                this.setState({
                    showSuccess: true
                });
            } else if (responseData.status  === 422) {
                if (typeof json !== 'object') {
                    throw new Error("Ungültige Antwort des Servers.");
                }
                this.setState({
                    formErrors: json
                });
            } else {
                let error = new Error(responseData.statusText);
                error.responseStatus = responseData.status;
                throw error;
            }
        })
        .catch(error => defaultErrorHandler(error));
    },

    populate(url) {
        if (this.state.submitInProgress) return;
        this.setState({ submitInProgress: true });

        const responseData = {};
        fetch(`${SERVER_URL}${url}`, {
            'method': "GET",
            headers: Auth.headers()
        })
        .then(r => {
            responseData.status = r.status;
            responseData.statusText = r.statusText;
            const contentType = r.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return r.json();
            } else {
                return r.text();
            }
        })
        .then(json => {
            this.setState({ submitInProgress: false });
            if (responseData.status === 200) {
                if (typeof json !== 'object') {
                    throw new Error("Ungültige Antwort des Servers.");
                }
                const newContent = {};
                Object.assign(newContent, this.state.formContent);
                for (var field in this.state.formContent) {
                    if (json[field] !== undefined) {
                        newContent[field] = json[field];
                    }
                }
                this.setState({ formContent: newContent });
            } else {
                let error = new Error(responseData.statusText);
                error.responseStatus = responseData.status;
                throw error;
            }
        })
        .catch(error => defaultErrorHandler(error));
    },

    GlobalErrors({ formErrors }) {
        if (formErrors.globalErrors == null || formErrors.globalErrors.total === 0) return null;
        return (
            <Row>
                <Col>
                    {formErrors.globalErrors.errors.map((error) => (
                        <Alert color="danger" key={error.message}>
                            {error.message}
                        </Alert>
                    ))}
                </Col>
            </Row>
        )
    },

    hasFieldErrors(field, formErrors) {
        if (formErrors.fieldErrors == null || formErrors.fieldErrors.total === 0) return false;
        for (var i in formErrors.fieldErrors.errors) {
            if (formErrors.fieldErrors.errors[i].field === field) return true;
        }
        return false;
    },

    FieldErrors({ field, formErrors }) {
        if (formErrors.fieldErrors == null || formErrors.fieldErrors.total === 0) return null;
        var result = [];
        for (var i in formErrors.fieldErrors.errors) {
            if (formErrors.fieldErrors.errors[i].field === field) {
                result.push(
                    (
                    <FormFeedback key={field + formErrors.fieldErrors.errors[i].message}>
                        {formErrors.fieldErrors.errors[i].message}
                    </FormFeedback>
                    )
                );
            }
        }
        return result;
    },

    SuccessModal({ isOpen, successMessage, successPath }) {
        return (
            <Modal isOpen={isOpen}>
                <ModalBody>
                    {successMessage}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => navigate(successPath)}>OK</Button>
                </ModalFooter>
            </Modal>
        )
    }

}