import React from 'react';
import FormHelpers from './formHelpers';
import { Row, Col, Form, Input, FormGroup, Label, Button } from 'reactstrap';

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            /* begin vars for formHelpers */
            submitInProgress: false,
            formContent: {
                password: ''
            },
            formErrors: {},
            showSuccess: false,
            /* end vars for formHelpers */
        }
    }

    render() {
        return (
            <>
                <Row>
                    <Col xs={{ size:12 }} sm={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                        <h2>Passwort ändern</h2>
                        <FormHelpers.GlobalErrors formErrors={this.state.formErrors} />
                        <Form onSubmit={(e) => { FormHelpers.submitHandler.bind(this, e, `/user/${this.props.loginData.id}`, "PUT").call(); }}>
                            <FormGroup>
                                <Label for="password">Neues Passwort</Label>
                                <Input className={FormHelpers.hasFieldErrors('password', this.state.formErrors) ? "is-invalid" : ""} type='password' name='password' value={this.state.formContent.password} onChange={FormHelpers.inputChangeHandler.bind(this)} />
                                <FormHelpers.FieldErrors field="password" formErrors={this.state.formErrors} />
                            </FormGroup>
                            <FormGroup>
                                <Button type="submit">Speichern</Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <FormHelpers.SuccessModal isOpen={this.state.showSuccess} successMessage="Das neue Passwort wurde gespeichert." successPath="/" />
            </>
        )
    }
}

export default ChangePassword;