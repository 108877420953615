import React from 'react';
import {Table, Row, Col, Button } from 'reactstrap';
import { SERVER_URL } from './config';
import Auth from './security/auth';
import 'whatwg-fetch';
import { defaultErrorHandler } from './handlers/errorHandlers';
import DateHelpers from './dateHelpers';
import { navigate, Link } from '@reach/router';

class AdminUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        fetch(`${SERVER_URL}/user`, {
            method: 'GET',
            headers: Auth.headers()
        })
        .then(r => r.json())
        .then(json => this.setState({queryResult:json}))
        .catch(error => defaultErrorHandler(error));
    }

    checkMark(bool) {
        if (bool) {
            return (
                <i className="fa fa-check" aria-hidden="true"></i>
            )
        }
    }

    render() {
        return (
            <Row><Col>
            <h2>Benutzerverwaltung</h2>
            <p>
                <Button onClick={() => {navigate('/admin/createUser')}}>Neuer Benutzer</Button>
            </p>
            <Table id="users">
                <thead>
                    <tr>
                        <th>Login</th>
                        <th>Name</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Erstellt</th>
                        <th>Admin</th>
                        <th>Aktiv</th>
                    </tr>
                </thead>
                <tbody>
					{this.state.queryResult && this.state.queryResult.users.map((user, idx) => (
						<tr key={user.id}>
						    <td><Link to={"/admin/editUser/"+user.id}>{user.username}</Link></td>
						    <td>{user.fullName}</td>
						    <td>{user.phone}</td>
						    <td>{user.email}</td>
						    <td className="date">{DateHelpers.formatDateTime(user.dateCreated)}</td>
						    <td className="boolean">{this.checkMark(user.isAdmin)}</td>
						    <td className="boolean">{this.checkMark(user.enabled)}</td>
						</tr>
					))}
                </tbody>
            </Table>
            </Col></Row>
        );
    }

}

export default AdminUsers;